import React, { Fragment, useState } from "react"
import Typist from "react-typist"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import "./features.less"
import { appUrl } from "../helpers/helpers"

import phoneicon from "../images/produceplan-iphoneicon.svg"
import pnfChannelImg from "../images/pnf-channel.svg"
import pnfLogisticsImg from "../images/pnf-logistics.svg"
import pnfPlanningImg from "../images/pnf-planning.svg"
import pnfSupplyImg from "../images/pnf-supply.svg"

import forkliftImg from "../images/forklift.svg"
import cloudsImg from "../images/clouds.svg"

import logoUF from "../images/logo-uf.png"
import logoQB from "../images/logo-qb.png"
import logoRL from "../images/logo-rl.png"
import logoPTI from "../images/logo-pti.png"
import logoTMR from "../images/logo-tmr.png"
import logoAWS from "../images/logo-aws.png"
import logoCync from "../images/logo-cync.png"
import logoSps from "../images/logo-sps.png"
import logoMailchimp from "../images/logo-mailchimp.png"
import logoSap from "../images/logo-sap.webp"
import logoOracleErp from "../images/logo-oracleerp.png"

const signupUrl = `${appUrl}/signup`
const ctaUrl = `${appUrl}/orders/new?source=produceplan.com`

const typings = [
  ["Sell", "Manage"],
  ["Monitor", "Communicate"],
  ["Grow", "Source"],
  ["Track", "Trace"],
]

const FeaturesPage = () => {
  const [typingIndex, setTypingIndex] = useState(0)

  return (
    <Layout headerColor="#BDEAFF">
      <SEO title="Products & Features" />
      <div id="home-b" className="features-page">
        <div
          className="home-panel"
          id="hero-b"
          style={{ backgroundColor: "#BDEAFF" }}
        >
          <div className="container relative">
            <h2>
              <Typist
                key={typingIndex}
                onTypingDone={() =>
                  setTypingIndex((typingIndex + 1) % typings.length)
                }
              >
                {typings.map((typing, i) => {
                  return (
                    <span key={`typing-${i}`}>
                      <span className="hero-red-text">{typing[0]}</span> +{" "}
                      <span className="hero-red-text">{typing[1]}</span>
                      <Typist.Backspace
                        count={typing[0].length + typing[1].length + 3}
                        delay={2000}
                      />
                    </span>
                  )
                })}
              </Typist>
              <br />
              with a connected food supply chain
            </h2>
            <img src={forkliftImg} alt="forklift" className="forklift-img" />
          </div>
          <div className="container">
            <div id="hero-b-left">
              <p className="hero-main-p">
                Today's food needs to quickly make it to tomorrow.{" "}
                <br className="but-not-on-mobile" />
                Manage and engage with your growers, customers and vendors{" "}
                <br className="but-not-on-mobile" />
                at the speed your supply chain deserves.
              </p>
              <img src={cloudsImg} alt="clouds" className="clouds-img" />
            </div>
            <div id="hero-b-right">
              <div id="cta-buttons" className="cta-buttons">
                <a
                  href={ctaUrl}
                  className="btn btn-green btn-shadowless btn-rounded green"
                >
                  Get started →
                </a>
                <a
                  href="/roi-calculator"
                  className="btn white black-text btn-shadowless btn-rounded"
                >
                  Calculate your savings →
                </a>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row hero-b-bottom">
              <p>
                Get the All-in-One <strong>Pre-ERP Suite</strong> for your fresh
                produce supply chain
              </p>
            </div>
          </div>

          <div className="container">
            <div className="row hero-cards">
              <div className="col s12 m3">
                <Link to="/features">
                  <div className="card">
                    <div className="card-image">
                      <img src={pnfPlanningImg} alt="Demand Planning" />
                    </div>
                  </div>
                </Link>
                <div className="card-caption">
                  <Link to="/features">Demand Planning</Link>
                </div>
              </div>
              <div className="col s12 m3">
                <Link to="/features">
                  <div className="card">
                    <div className="card-image">
                      <img src={pnfSupplyImg} alt="Supply Forecasting" />
                    </div>
                  </div>
                </Link>
                <div className="card-caption">
                  <Link to="/features">Supply Forecasting</Link>
                </div>
              </div>
              <div className="col s12 m3">
                <Link to="/features/quickbooks-integration">
                  <div className="card">
                    <div className="card-image">
                      <img src={pnfChannelImg} alt="Orders & Invoicing" />
                    </div>
                  </div>
                </Link>
                <div className="card-caption">
                  <Link to="/features/quickbooks-integration">
                    Orders & Invoicing
                  </Link>
                </div>
              </div>
              <div className="col s12 m3">
                <Link to="/traceability">
                  <div className="card">
                    <div className="card-image">
                      <img src={pnfLogisticsImg} alt="Warehousing & Freight" />
                    </div>
                  </div>
                </Link>
                <div className="card-caption">
                  <Link to="/traceability">Warehousing & Freight</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row hero-cards">
              <div className="col s12 m5">
                <div className="end-to-end-line"></div>
              </div>
              <div className="col s12 m2">
                <div className="end-to-end">End-to-End</div>
              </div>
              <div className="col s12 m5">
                <div className="end-to-end-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-features-partners">
        <div className="container">
          <div className="row hero-b-bottom">
            <p>
              <strong>Partnering & Integrating</strong> with technology brands
              you know and love
            </p>
          </div>
        </div>

        <div className="container-large">
          <div className="row partner-cards">
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoUF} alt="Uber Freight" />
                </div>
              </div>
            </div>
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoQB} alt="Quickbooks" />
                </div>
              </div>
            </div>
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoPTI} alt="PTI Print" />
                </div>
              </div>
            </div>
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoTMR} alt="Tomorrow.io" />
                </div>
              </div>
            </div>
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoAWS} alt="Amazon Web Services" />
                </div>
              </div>
            </div>
          </div>

          <div className="row partner-cards">
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoCync} alt="Cync" />
                </div>
              </div>
            </div>
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoSps} alt="SPS" />
                </div>
              </div>
            </div>
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoMailchimp} alt="Mailchimp" />
                </div>
              </div>
            </div>
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoSap} alt="SAP" />
                </div>
              </div>
            </div>
            <div className="col s12 m2">
              <div className="card">
                <div className="card-image">
                  <img src={logoOracleErp} alt="Oracle ERP" />
                </div>
              </div>
            </div>
          </div>

          <div className="row action-buttons">
            <div className="center">
              <a href={ctaUrl} className="btn blue-grey darken-3 btn-cta">
                Get a full virtual tour
              </a>
              <span className="btn-row-or">
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; or
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              </span>
              <a href={signupUrl} className="btn orange btn-cta">
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FeaturesPage
